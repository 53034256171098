import Vue from 'vue';
import Router from 'vue-router';
// import Home from './views/Home.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      // component: Home,
      component: () => import('./views/Home.vue'),
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/AboutUs.vue'),
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('./views/ContactUs.vue'),
    },
    {
      path: '/apply',
      name: 'apply',
      component: () => import('./views/Apply2.vue'),
    },
    {
      path: '/process',
      name: 'process',
      component: () => import('./views/Process.vue'),
    },
    {
      path: '/property',
      name: 'property',
      component: () => import('./views/Property.vue'),
    },
    {
      path: '/calculator',
      name: 'calculator',
      component: () => import('./views/Calculator.vue'),
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('./views/RegisterUser.vue'),
    },
    {
      path: '/reset-pwd',
      name: 'reset-pwd',
      component: () => import('./views/RegisterUser.vue'),
      props: { formType: '重設密碼' },
    },
    {
      path: '/loan-app',
      name: 'loan-app',
      component: () => import('./views/LoanApp.vue'),
    },
    {
      path: '/loan-detail/:loan_id',
      name: 'loan-detail',
      component: () => import('./views/LoanDetail.vue'),
    },
    {
      path: '/download',
      name: 'download',
      component: () => import('./views/Download.vue'),
    },
    {
      path: '/disclaimer',
      name: 'disclaimer',
      component: () => import('./views/Disclaimer.vue'),
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('./views/Partners.vue'),
    },
    // {
    //   path: '/document',
    //   name: 'document',
    //   component: () => import('./views/LoanDocuments.vue'),
    // },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
