import Vue from 'vue';
// import Framework7 from 'framework7';
// import Framework7Vue from 'framework7-vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
// const VueInputMask = require('vue-inputmask').default;
import VueInputMask from 'vue-inputmask';

Vue.use(VueInputMask);

// Init plugin
// Framework7.use(Framework7Vue);
Vue.use(ElementUI);

Vue.config.productionTip = false;

// Constants
const AppInfo = Object.freeze({
  name: 'MatchingPro App',
  version: '1.0.2',
  helpers: {
    test() {
      return null;
    },
  },
});

Vue.prototype.$AppInfo = {
  isMobileRequest: false,
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    (this as any).$AppInfo.isMobile = true;
  },
}).$mount('#app');


