import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginSess: '',
    mobile: '',
  },
  getters: {
    isLogined: (state) => (state.loginSess !== ''),
  },
  mutations: {
    logined(state, payload) {
      state.loginSess = payload.loginSess;
      state.mobile = payload.mobile;
    },
    logout(state) {
      state.loginSess = '';
      state.mobile = '';
    },
  },
  actions: {
    logined({commit}, payload) {
      commit('logined', payload);
    },
    logout({commit}) {
      commit('logout');
    },
  },
});
