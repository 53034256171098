
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import $ from 'jquery';

export default Vue.extend({
    data() {
        return {
            isMobileRequest: (this as any).$AppInfo.isMobileRequest,
            // nothing
        };
    },
    computed: {
        ...mapGetters([
            'isLogined',
        ]),
    },
    watch: {
        $route() {
            // console.log('close menu');
            this.closeNavMenu();
        },
    },
    methods: {
        closeNavMenu() {
            $('#navbarSupportedContent').removeClass('show');
        },
        logout() {
            this.closeNavMenu();
            this.$confirm('是否確認退出?', '', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning',
            }).then(() => {
                this.$store.dispatch('logout');
                this.$router.push('/login');
            }).catch(() => {
                // nothing
            });
        },
        test() {
            // nothing
        },
    },
});
