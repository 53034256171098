
















import { Component, Vue } from 'vue-property-decorator';
import Header from './views/Header.vue';
import Footer from './views/Footer.vue';

const DEFAULT_TRANSITION = 'fade';
// const DEFAULT_TRANSITION = 'slide';
// const DEFAULT_TRANSITION = '';

export default Vue.extend({
  name: 'app',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      prevHeight: 0 as any,
      transitionName: DEFAULT_TRANSITION,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  methods: {
    beforeLeave(element: any) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element: any) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element: any) {
      element.style.height = 'auto';
    },
  },
});
